import { PulseLoader } from "react-spinners";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import { PrivacyPolicy } from "routes";
import { CallBackURL } from "routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { useSelector } from "react-redux";
import { resetStore } from "Store/user";
import { useDispatch } from "react-redux";

const MainContainer = lazy(() =>
  import("../../src/views/MainContainer/MainContainer")
);
const Signin = lazy(() => import("../views/auth/Login/Login"));

function App() {
  const dispatch = useDispatch();
  const stateUser = useSelector((state)=>state.user)
  const authToken = localStorage.getItem("accessToken");
  const [showLoader, setShowLoader] = useState(false);
  const [showTheApp, setShowTheApp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 1000); // Delay the loader by 500 milliseconds (adjust as needed)

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  useEffect(()=>{
    if(stateUser?.userData?.roles[0] == "student"){
      setShowTheApp(true)
        localStorage.clear();
        dispatch(resetStore())
        // window.location.href = "/";
      
    }
  },[stateUser])

  return (
    <Suspense
      fallback={
        showLoader ? (
          <div className="d-flex align-items-center justify-content-center vh-100">
            <PulseLoader size={25} color="#0050ef" />
          </div>
        ) : null
      }
    >
      <BrowserRouter>
        <Routes>
          {/* Privacy Policy Route - Always accessible */}
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy.component/>}
          />
            <Route
            path="/callback"
            element={<CallBackURL.component/>}
          />

          {/* Redirect from root path to appropriate route */}
          <Route
            path="/"
            element={
              authToken ? (
                showTheApp ? (
                  // Show blank page after login if showTheApp is true
                  <div style={{ height: "100vh", backgroundColor: "#fff" }}></div>
                ) : (
                  <Navigate to="/dashboard" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />

          {/* If the user is authenticated, navigate to the main container */}
          <Route
            path="/*"
            element={
              authToken ? (
                showTheApp ? (
                  // Show blank page after login if showTheApp is true
                  <div style={{ height: "100vh", backgroundColor: "#fff" }}></div>
                ) : (
                  <MainContainer />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />

          {/* Redirect authenticated users away from the signin page */}
          <Route
            path="/signin"
            element={authToken ? <Navigate to="/dashboard" /> : <Signin />}
          />

    

          {/* Default Redirect */}
          <Route
            path="*"
            element={
              authToken ? (
                showTheApp ? (
                  // Show blank page after login if showTheApp is true
                  <div style={{ height: "100vh", backgroundColor: "#fff" }}></div>
                ) : (
                  <Navigate to="/dashboard" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
