import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getAssignmentListAction = ({ request, page }) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = request?.isRefresh
      ? types.ASSIGNMENT_LIST_REFRESH_REQUEST
      : types.ASSIGNMENT_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getAssignment(page);
      if (response.status == 200) {
        dispatch({
          type: types.ASSIGNMENT_LIST_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: types.ASSIGNMENT_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ASSIGNMENT_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createAssignmentAction = (
  request,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_ASSIGNMENT_REQUEST });
      const response = await ApiList.createAssignment(request);
      if (response.status === 200) {
        dispatch({
          type: types.CREATE_ASSIGNMENT_SUCCESS,
          payload: response.data,
        });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
        toast.success("Assignment Created Successfully");
      } else {
        dispatch({ type: types.CREATE_ASSIGNMENT_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_ASSIGNMENT_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const uploadAssignmentAction = (request, uuid, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPLOAD_ASSIGNMENT_REQUEST });
      const response = await ApiList.uploadAssignment(request, uuid);
      if (response.status === 200) {
        dispatch({
          type: types.UPLOAD_ASSIGNMENT_SUCCESS,
          payload: response.data,
        });
        toast.success("Pdf Uploaded Successfully");
        navigate("/task");
      } else {
        dispatch({ type: types.UPLOAD_ASSIGNMENT_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPLOAD_ASSIGNMENT_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignmentDetailAction = (uuid, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.ASSIGNMENT_DETAILS_REQUEST });
      const response = await ApiList.getAssignmentDetails(uuid);
      if (response.status === 200) {
        dispatch({
          type: types.ASSIGNMENT_DETAILS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({ type: types.ASSIGNMENT_DETAILS_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response?.data?.title);
      }
    } catch (error) {
      dispatch({ type: types.ASSIGNMENT_DETAILS_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getSubmissionAssignmentListAction = (uuid, navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = uuid?.isRefresh
      ? types.SUBMISSION_LIST_REFRESH_REQUEST
      : types.SUBMISSION_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getSubmissionAssignment(uuid);

      if (response.status == 200) {
        dispatch({
          type: types.SUBMISSION_LIST_SUCCESS,
          payload: response.data.data,
        });
        navigate("/task/task-submission-list", { state: uuid });
      } else {

        dispatch({
          type: types.SUBMISSION_LIST_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.SUBMISSION_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const updateAssignmentAction = (
  request,
  uuid,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_ASSIGNMENT_REQUEST });
      const response = await ApiList.updateAssignment(request, uuid);
      if (response.status === 200) {
        dispatch({
          type: types.UPDATE_ASSIGNMENT_SUCCESS,
          payload: response.data,
        });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
        toast.success("Assignment Updated Successfully");
      } else {
        dispatch({ type: types.UPDATE_ASSIGNMENT_ERROR, payload: response });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_ASSIGNMENT_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const updateUploadAssignmentAction = (request, uuid, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_UPLOAD_ASSIGNMENT_REQUEST });
      const response = await ApiList.updateAssignment(request, uuid);
      if (response.status === 200) {
        dispatch({
          type: types.UPDATE_UPLOAD_ASSIGNMENT_SUCCESS,
          payload: response.data,
        });
        toast.success("Pdf Updated Successfully");
        navigate("/task");
      } else {
        dispatch({
          type: types.UPDATE_UPLOAD_ASSIGNMENT_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_UPLOAD_ASSIGNMENT_ERROR, payload: error });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const deleteAssignmentAction = (uuid, setModalShow, callBack) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.DELETE_ASSIGNMENT_REQUEST });
      const response = await ApiList.deleteAssignment(uuid);
      if (response.status === 200) {
        dispatch({
          type: types.DELETE_ASSIGNMENT_SUCCESS,
          payload: response.data,
        });
        toast.success("Assignment Deleted Successfully");
        setModalShow(false);
        if (callBack) callBack();
      } else {
        dispatch({
          type: types.DELETE_ASSIGNMENT_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({
        type: types.DELETE_ASSIGNMENT_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const getAssignPdfLinkAction = (uuid, setPdfLink) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.GET_ASSIGN_PDF_LINK_REQUEST });
      const response = await ApiList.getAssignPdfLink(uuid);
      if (response.status === 200) {
        dispatch({
          type: types.GET_ASSIGN_PDF_LINK_SUCCESS,
          payload: response.data,
        });

        setPdfLink(response?.data?.data?.url);
      } else {
        dispatch({
          type: types.GET_ASSIGN_PDF_LINK_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({
        type: types.GET_ASSIGN_PDF_LINK_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const getStudentAssignPdfLinkAction = (uuid, setPdfLink) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.STUDENT_GET_ASSIGN_PDF_LINK_REQUEST });
      const response = await ApiList.getStudentAssignPdfLink(uuid);

      if (response.status === 200) {
        dispatch({
          type: types.STUDENT_GET_ASSIGN_PDF_LINK_SUCCESS,
          payload: response.data,
        });
        setPdfLink(response?.data?.data?.download_url);
      } else {
        dispatch({
          type: types.STUDENT_GET_ASSIGN_PDF_LINK_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      dispatch({
        type: types.STUDENT_GET_ASSIGN_PDF_LINK_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignAssignmentToStudentAction = (
  uuid,
  request,
  callBack,
  close
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.ASSIGN_ASSIGNMENT_TO_STUDENT_REQUEST });
      const response = await ApiList.assignAssignmentToStudent(uuid, request);
      if (response.status === 200) {
        dispatch({
          type: types.ASSIGN_ASSIGNMENT_TO_STUDENT_SUCCESS,
          payload: response.data,
        });
        if (callBack) callBack();
        if (close) close();
        toast.success(response?.data?.data?.message);
      } else {
        dispatch({
          type: types.ASSIGN_ASSIGNMENT_TO_STUDENT_ERROR,
          payload: response,
        });
        // setTimeout(() => AlertHelper.warning(response?.message), 100);
        toast.error(response);
      }
    } catch (error) {
      // console.log("errr", error);
      dispatch({
        type: types.STUDENT_GET_ASSIGN_PDF_LINK_ERROR,
        payload: error,
      });
      // setTimeout(() => AlertHelper.warning(error), 100);
      toast.error(error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignmentApprovedAction = (
  uuid,
  request,
  callBack,
  close,
  navigate
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.APPROVED_ASSIGNMENT_REQUEST });
      const response = await ApiList.assignmentApproved(uuid, request);
      if (response.status === 200) {
        dispatch({
          type: types.APPROVED_ASSIGNMENT_STUDENT_SUCCESS,
          payload: response.data,
        });
        if (close) close()
        if (callBack) callBack();
        toast.success("Status Updated Successfully");
      } else {
        dispatch({
          type: types.APPROVED_ASSIGNMENT_STUDENT_ERROR,
          payload: response,
        });

        toast.error(response?.error?.message);
      }
    } catch (error) {
      dispatch({
        type: types.APPROVED_ASSIGNMENT_STUDENT_ERROR,
        payload: error,
      });
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
